<template>
  <div class="vps-detail">
    <transition name="fade" mode="out-in">
      <div v-if="isCurrentLoading" class="vps-detail__loader">
        <base-loader class="vps-detail__loader-item" />
      </div>
      <base-alert
        v-else-if="!isCurrentLoading && isServerError"
        :title="$t('errors.serverError.title')"
        icon="warn"
        class="vps-info__alert"
      >
        {{ $t('errors.serverError.text') }}
      </base-alert>
      <div v-else-if="tariff && tariff.status" class="vps-detail__inner">
        <div class="vps-detail__content">
          <layout-stack-title-menu
            :title="tariff.name"
            :address="tariff.id"
            :status="tariff.status"
            :description="tariff.description"
            :state="state(tariff)"
            :specs="specsObj"
            class="vps-detail__head"
          >
            <template #btn>
              <v-popover placement="bottom" popover-class="vps-detail__popover">
                <sidebar-button />
                <template #popover>
                  <router-context-menu
                    :tariff="tariff"
                    :is-loading="isCurrentLoading"
                    class="vps-detail__context-menu"
                  />
                </template>
              </v-popover>
            </template>
            <template #button>
              <base-button
                :icon="isIcon"
                :color="isColor"
                :tooltip="{
                  content: text,
                  autoHide: false,
                  placement: 'left-start',
                  container: false,
                  trigger: 'click hover',
                }"
                class="subnet-info__create-btn"
                @click="clickActionButton(tariff)"
                >{{ $t('addSubnet') }}</base-button
              >
            </template>
          </layout-stack-title-menu>
          <tabs :list="nav" button="subnet" class="medium-title">
            <template v-slot:item="{ item }">
              <router-link exact-active-class="active" :to="item.to">
                {{ item.title }}
              </router-link>
            </template>
          </tabs>
          <transition name="slide-fade">
            <router-view v-if="tariff" :tariff="tariff"></router-view>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LayoutStackTitle from '@/components/LayoutTitle/LayoutStackTitle.vue';
import SidebarButton from '@/components/Buttons/SidebarButton.vue';
import MainCard from '@/components/MainCard/MainCard';
import RouterContextMenu from '../pages/Main/components/RouterContextMenu';
import BaseAlert from '@/components/BaseAlert/BaseAlert';
import storeMixin from '../mixins/index';
import breakpoint from '@/utils/breakpoints';
import providerChange from '@/mixins/providerChange';
import tabs from '@/components/Tabs/Tabs';
import attachSubnet from '@/mixins/attachSubnet';
import LayoutStackTitleMenu from '@/components/LayoutTitle/LayoutStackTitleMenu.vue';
export default {
  name: 'Router',
  components: {
    LayoutStackTitleMenu,
    SidebarButton,
    // MainCard,
    RouterContextMenu,
    BaseAlert,
    tabs,
  },
  mixins: [storeMixin, providerChange, attachSubnet],
  props: {
    routerid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      button: {
        type: 'subnet',
        quota: false,
      },
      nav: [
        {
          title: this.$t('nav.router'),
          to: { name: 'RouterView', params: { routerid: this.routerid } },
        },
      ],
      isCurrentLoading: false,
      isServerError: false,
      isMobile: true,
    };
  },
  computed: {
    tariff() {
      return this.$store.state.moduleStack.routers.find(x => x.id === this.routerid);
    },
    screenWidth() {
      return this.$store.state.moduleApp.screen.width;
    },
    currentQuota() {
      return false;
    },
    isIcon() {
      return this.currentQuota ? 'error' : '';
    },
    isColor() {
      return this.currentQuota ? 'warn' : 'primary';
    },
    text() {
      if (this.currentQuota) {
        return 'Все подсети уже привязаны.';
      } else {
        return 'Привязать новую подсеть';
      }
    },
    specsObj() {
      const specs = this.tariff?.external_gateway_info?.external_fixed_ips[0];

      let list = [];
      if (specs?.ip_address) list.push(specs.ip_address);
      return {
        list,
      };
    },
  },
  watch: {
    screenWidth: function () {
      this.setIsMobile();
    },
  },
  mounted() {
    this.$nextTick(() => this.setIsMobile());
    this.newFetch();
  },
  methods: {
    clickActionButton(tariff) {
      if (this.currentQuota) {
        return '';
      } else return this.attachSubnet(tariff);
    },
    setIsMobile() {
      this.isMobile = this.screenWidth < breakpoint.md;
    },
    async onProviderChange() {
      await this.$store.dispatch('moduleStack/reset', 16);
    },
    state(item) {
      const state = item.admin_state_up;
      return {
        color: state === true ? 'success' : 'error',
        key: state === true ? 'running' : 'stopped',
      };
    },
  },
  // },
};
</script>

<i18n>
  {
    "ru": {
      "addSubnet": "Привязать подсеть",
      "sure": {
        "confirm": "Подключить",
        "success": "Подсеть добавлена успешно.",
        "fail": "Что-то пошло не так",
        "close": "Закрыть"
      },
      "nav": {
        "router": "Подсети"
      }
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
$vd-aside-size = 260px;
.vps-detail {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;

  &__loader {
    flexy(center, center);
    min-size(100%);
    flex: 1 1 100%;
  }

  &__inner {
    position: relative;
    +breakpoint(md-and-up) {
      flexy(space-between, flex-start);
    }
  }

  &__content {
    flex: 1 1 100%;
    min-width: 'calc(100% - %s - 1.5rem)' % $vd-aside-size;
  }

  &__aside {
    display: none;

    +breakpoint(md-and-up) {
      display: block;
      flex: 0 0 $vd-aside-size;
      margin-left: 1.5rem;
    }
  }

  &__head {
    margin-bottom: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-bottom: 1.5rem;
    }
  }

  &__context-menu {
    max-width: 100%;
    width: calc(100vw - 4rem);

    +breakpoint(sm-and-up) {
      width: 100%;
    }
  }
}
</style>
<style lang="stylus">
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.vps-detail {
  &__popover {
    .popover-inner {
      transform: translateX(-0.75rem)
    }
  }
}
</style>
